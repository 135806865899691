import React, { useEffect } from "react"
import { gsap } from "gsap";

const Init = ({route}) => {

    useEffect(()=>{

        const scaleCursor = (amt) => {
            gsap.to('#cursor', {
                duration: 0.5,
                scale: amt,
                opacity: 1,
                ease: "Power3.easeOut",
            });
        }

        scaleCursor(0.2); 

        const hoverEventListener = () => {
            const hoverItems = document.querySelectorAll('.data-hover');
            hoverItems.forEach(item => {
                item.addEventListener('mouseenter', () => {
                    scaleCursor(1);
                    gsap.to('#cursor span', {
                        opacity: 1,
                        ease: "Power3.easeOut",
                        duration: 1,
                    });
                });
    
                item.addEventListener('mouseleave', () => {
                    scaleCursor(0.2);
                    gsap.to('#cursor span', {
                        opacity: 0,
                        ease: "Power3.easeOut",
                        duration: 1,
                    });
                });
            });

            const hideCursorItems = document.querySelectorAll('.hide-cursor');
            hideCursorItems.forEach(item => {
                item.addEventListener('mouseenter', () => {
                    scaleCursor(0);
                    gsap.to('#cursor span', {
                        opacity: 1,
                        ease: "Power3.easeOut",
                        duration: 1,
                    });
                });
    
                item.addEventListener('mouseleave', () => {
                    scaleCursor(0.2);
                    gsap.to('#cursor span', {
                        opacity: 0,
                        ease: "Power3.easeOut",
                        duration: 1,
                    });
                });
            });
        }
    
        hoverEventListener();

    },[route]);

    return (<></>)
}

export default Init