import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import * as style from "./footer.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/free-solid-svg-icons"

import PDF from '../../static/Resume.pdf'

const Footer = ({mode}) => (
  <footer className={mode === 'dark' ? style.dark : null}>
    <div className={`content`}>
      <div className={style.split}>
        <div className={style.left}>
          <p className={`primary`}>Coded with <span className={style.icon}><FontAwesomeIcon icon={faHeart} /></span> by Abhinav Thukral</p>
        </div>
        <div className={style.right}>
          <div className={style.links}>
            <a href={PDF} target={`_blank`}><div className={`${style.link} data-hover`}>Resume</div></a>
            <Link to="/contact"><div className={`${style.link} data-hover`}>Get in touch</div></Link>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  mode: PropTypes.string,
}

Footer.defaultProps = {
  mode: `light`,
}

export default Footer
