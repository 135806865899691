exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lab-js": () => import("./../../../src/pages/lab.js" /* webpackChunkName: "component---src-pages-lab-js" */),
  "component---src-pages-projects-aesop-js": () => import("./../../../src/pages/projects/aesop.js" /* webpackChunkName: "component---src-pages-projects-aesop-js" */),
  "component---src-pages-projects-basics-of-js": () => import("./../../../src/pages/projects/basics-of.js" /* webpackChunkName: "component---src-pages-projects-basics-of-js" */),
  "component---src-pages-projects-bots-at-work-js": () => import("./../../../src/pages/projects/bots-at-work.js" /* webpackChunkName: "component---src-pages-projects-bots-at-work-js" */),
  "component---src-pages-projects-diabetes-tracker-js": () => import("./../../../src/pages/projects/diabetes-tracker.js" /* webpackChunkName: "component---src-pages-projects-diabetes-tracker-js" */),
  "component---src-pages-projects-drive-js": () => import("./../../../src/pages/projects/drive.js" /* webpackChunkName: "component---src-pages-projects-drive-js" */),
  "component---src-pages-projects-expression-js": () => import("./../../../src/pages/projects/expression.js" /* webpackChunkName: "component---src-pages-projects-expression-js" */),
  "component---src-pages-projects-high-museum-js": () => import("./../../../src/pages/projects/high-museum.js" /* webpackChunkName: "component---src-pages-projects-high-museum-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-lucid-js": () => import("./../../../src/pages/projects/lucid.js" /* webpackChunkName: "component---src-pages-projects-lucid-js" */),
  "component---src-pages-projects-mastercard-js": () => import("./../../../src/pages/projects/mastercard.js" /* webpackChunkName: "component---src-pages-projects-mastercard-js" */),
  "component---src-pages-projects-poetic-ai-js": () => import("./../../../src/pages/projects/poetic-ai.js" /* webpackChunkName: "component---src-pages-projects-poetic-ai-js" */),
  "component---src-pages-projects-posture-analysis-js": () => import("./../../../src/pages/projects/posture-analysis.js" /* webpackChunkName: "component---src-pages-projects-posture-analysis-js" */),
  "component---src-pages-projects-sign-language-translation-js": () => import("./../../../src/pages/projects/sign-language-translation.js" /* webpackChunkName: "component---src-pages-projects-sign-language-translation-js" */),
  "component---src-pages-projects-workaholic-js": () => import("./../../../src/pages/projects/workaholic.js" /* webpackChunkName: "component---src-pages-projects-workaholic-js" */),
  "component---src-pages-random-bus-tracking-js": () => import("./../../../src/pages/random/bus-tracking.js" /* webpackChunkName: "component---src-pages-random-bus-tracking-js" */),
  "component---src-pages-random-cryptobots-js": () => import("./../../../src/pages/random/cryptobots.js" /* webpackChunkName: "component---src-pages-random-cryptobots-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-travel-js": () => import("./../../../src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */)
}

