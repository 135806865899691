import React, { useEffect, useRef } from "react"
import * as style from "./cursor.module.scss"
import { lerp, getMousePos } from './utils'
import { gsap } from "gsap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

const Cursor = ({ mode }) => {

    const cursorElem = useRef(null);

    const current = { x: 0, y: 0 };
    const prev = { x: 0, y: 0 };

    const render = () => {
        prev.x = lerp(prev.x, current.x, 0.1);
        prev.y = lerp(prev.y, current.y, 0.1);
        document.getElementById('cursor').style.left = `${prev.x}px`;
        document.getElementById('cursor').style.top = `${prev.y}px`;
        requestAnimationFrame(render);
    }

    const mouseMove = (e) => {
        let mouse = getMousePos(e);
        current.x = mouse.x;
        current.y = mouse.y;
    }

    useEffect(() => {
        window.addEventListener("mousemove", mouseMove);
        var animation = requestAnimationFrame(render);

        const scaleCursor = (amt) => {
            gsap.to('#cursor', {
                duration: 0.5,
                scale: amt,
                opacity: 1,
                ease: "Power3.easeOut",
            });
        }

        scaleCursor(0.2);

        gsap.from('#cursor', {
            opacity: 0,
            ease: "Power3.easeOut",
            duration: 2,
            delay: 1,
        });

        return () => {
            window.removeEventListener("mousemove", mouseMove)
            cancelAnimationFrame(animation);
        }
    }, []);

    return (
        <div id="cursor" ref={cursorElem} className={mode === 'dark' ? `${style.cursor} ${style.dark}` : `${style.cursor}`}>
            <span><FontAwesomeIcon icon={faArrowRight} /></span>
        </div>
    )
}

export default Cursor