/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header/header"
import Footer from "./footer/footer"
import Cursor from "./cursor/cursor"
import "./layout.scss"
import Init from "./initializer/initializer"

const isBrowser = typeof window !== "undefined"

const Layout = ({ tab, children }) => {

  // const storedTheme = typeof window !== 'undefined' && window.localStorage.getItem('theme');
  // const [theme, setTheme] = useState(storedTheme ? storedTheme : 'light');
  const [theme, setTheme] = useState('light');

  // useEffect(() => {
  //   if(storedTheme === 'dark'){
  //     document.body.className = 'dark';
  //   }
  // }, []);

  const toggleTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
      document.body.className = 'dark';
      window.localStorage.setItem('theme', 'dark');
    } else if (theme === 'dark') {
      setTheme('light');
      document.body.className = '';
      window.localStorage.setItem('theme', 'light');
    }
  }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Cursor mode={theme} />
      <Init route={isBrowser ? document.location.href : null} />
      <Header activeTab={tab} siteTitle={data.site.siteMetadata?.title || `Abhinav Thukral`} mode={theme} modeSwitch={toggleTheme} />
      <main className={theme === 'dark' ? 'dark' : null}>
        {children}
      </main>
      <Footer mode={theme} />
    </>
  )
}

Layout.propTypes = {
  tab: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Layout
